import { isItemBedbankUpcoming, isItemUpcoming } from './isUpcoming'

const SUPPORTED_UPSELL_OFFER_TYPES: Array<App.OfferType> = [
  'hotel',
  'bedbank_hotel',
  'bundle_and_save',
  'last_minute_hotel',
  'rental',
  'tactical_ao_hotel',
]

export function upsellEnabledForOrder(order: App.Order): boolean {
  const upcomingAndSupportedItems:Array<App.OrderItem | App.OrderBedbankItem> = order.items.filter(item => {
    const supported = SUPPORTED_UPSELL_OFFER_TYPES.includes(item.offer.type)
    const upcomingAndSupported = isItemUpcoming(item) && supported
    if (item.offer.type === 'hotel') {
      // BNBLs are not supported for upsell so check for reservation (i.e. that they have dates) on flash
      return upcomingAndSupported && item.reservation
    } else {
      return upcomingAndSupported
    }
  })
  upcomingAndSupportedItems.concat(order.bedbankItems.filter(item => isItemBedbankUpcoming(item)))
  return upcomingAndSupportedItems.length > 0
}

export function getUpsellDismissalStorageKey(upsell: string, orderId: string) {
  return `${upsell}-${orderId}-upsell-dismissed`
}
